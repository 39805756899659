//------------------------------------------------------------------------------------//
//                                                                                    //
//                            LOADING INTERFACE                                       //
//                                                                                    //
//------------------------------------------------------------------------------------//
export const SET_LOADING_FOOD = "SET_LOADING_FOOD";
export const SET_LOADING_SIKI = "SET_LOADING_SIKI";
export const SET_LOADING_DOBRECHUTNE = "SET_LOADING_DOBRECHUTNE";
export const SET_LOADING_ALTIS = "SET_LOADING_ALTIS";
//------------------------------------------------------------------------------------//
//                                                                                    //
//                            GLOBAL INTERFACE                                        //
//                                                                                    //
//------------------------------------------------------------------------------------//
//Load foods
export const LOAD_FOOD_STARTED = "LOAD_FOOD_STARTED";
export const LOAD_FOOD = "LOAD_FOOD";
export const LOAD_FOOD_FAILED = "LOAD_FOOD_FAILED";
export const LOAD_SIKI_FOOD_STARTED = "LOAD_SIKI_FOOD_STARTED";
export const LOAD_SIKI_FOOD = "LOAD_SIKI_FOOD";
export const LOAD_SIKI_FOOD_FAILED = "LOAD_SIKI_FOOD_FAILED";
export const LOAD_DOBRECHUTNE_FOOD_STARTED = "LOAD_DOBRECHUTNE_FOOD_STARTED";
export const LOAD_DOBRECHUTNE_FOOD = "LOAD_DOBRECHUTNE_FOOD";
export const LOAD_DOBRECHUTNE_FOOD_FAILED = "LOAD_DOBRECHUTNE_FOOD_FAILED";
export const LOAD_ALTIS_FOOD_STARTED = "LOAD_ALTIS_FOOD_STARTED";
export const LOAD_ALTIS_FOOD = "LOAD_ALTIS_FOOD";
export const LOAD_ALTIS_FOOD_FAILED = "LOAD_ALTIS_FOOD_FAILED";
