import { Reducer } from "redux";
import { IGlobalStore } from "../constants/store-interfaces";
import {
  LOAD_SIKI_FOOD_STARTED,
  LOAD_SIKI_FOOD,
  LOAD_DOBRECHUTNE_FOOD,
  LOAD_ALTIS_FOOD,
} from "../constants/action-types";
import { LOAD_FOOD_STARTED, LOAD_FOOD } from "../constants/action-types";

const initialState: IGlobalStore = {
  restaurants: [],
  siki: "",
  altis: "",
  dobrechutne: "",
};

const reducer: Reducer<IGlobalStore> = (state = initialState, action) => {
  let was = false;

  switch (action.type) {
    case LOAD_FOOD_STARTED:
      state = {
        ...state,
        restaurants: [],
      };
      was = true;
      break;
    case LOAD_SIKI_FOOD_STARTED:
      state = {
        ...state,
        siki: "",
      };
      was = true;
      break;
    case LOAD_FOOD:
      state = {
        ...state,
        restaurants: action.payload,
      };
      was = true;
      break;
    case LOAD_ALTIS_FOOD:
      state = {
        ...state,
        altis: action.payload,
      };
      was = true;
      break;
    case LOAD_SIKI_FOOD:
      state = {
        ...state,
        siki: action.payload,
      };
      was = true;
      break;
    case LOAD_DOBRECHUTNE_FOOD:
      state = {
        ...state,
        dobrechutne: action.payload,
      };
      was = true;
      break;
  }

  if (action.type.endsWith("_FAILED") && was) {
    //TODO show error
  }

  return state;
};

export { reducer as globalReducer };
