import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import RestaurantDay from "../parts/RestaurantDay";
import { IApplicationStore } from "../../../constants/store-interfaces";
import { useSelector } from "react-redux";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { Days } from "../../../constants/types";
import useIsMobile from "../../../hooks/useIsMobile";
import RestaurantImageDay from "../parts/RestaurantImageDay";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
  },
  root: {
    flexGrow: 1,
  },
  tab: {},
  container: {
    width: "100%",
  },
}));

export default function MainPage() {
  const isMobile = useIsMobile();

  const resObject = useSelector(
    (state: IApplicationStore) => state.globalStore.restaurants
  );
  const siki = useSelector(
    (state: IApplicationStore) => state.globalStore.siki
  );
  const sikiLoading = useSelector(
    (state: IApplicationStore) => state.loadingStore.loadingSiki
  );
  const altis = useSelector(
    (state: IApplicationStore) => state.globalStore.altis
  );
  const altisLoading = useSelector(
    (state: IApplicationStore) => state.loadingStore.loadingAltis
  );
  const dch = useSelector(
    (state: IApplicationStore) => state.globalStore.dobrechutne
  );
  const dchLoading = useSelector(
    (state: IApplicationStore) => state.loadingStore.loadingDobreChutne
  );

  const [value, setValue] = React.useState(
    Math.min(new Date().getDay() - 1, 4)
  );

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const days: Days[] = [
    Days.pondelok,
    Days.utorok,
    Days.streda,
    Days.stvrtok,
    Days.piatok,
  ];

  const skipRestaurants = [
    "Šmak",
    "LG Catering",
    "Restauracia Salas - Nizna nad Slovnaftom",
  ];
  const restaurants = resObject.filter(
    (r) => !skipRestaurants.includes(r.name)
  );

  const favorites = ["Hotel Rohac ***", "Restauracia ALTIS"];

  //   const favoritesOther = [];

  const firstInTop = ["Hotel Rohac ***", "Restauracia Escale"];

  let lastFavoriteRestaurants = restaurants
    .filter((r) => favorites.includes(r.name) && !firstInTop.includes(r.name))
    .reverse();

  let favoriteRestaurants = restaurants
    .filter((r) => firstInTop.includes(r.name))
    .reverse();

  favoriteRestaurants = favoriteRestaurants.concat(lastFavoriteRestaurants);

  //   const favoritesOtherRestaurants = restaurants.filter((r) =>
  //     favoritesOther.includes(r.name)
  //   );

  const nonFavoriteRestaurants = restaurants.filter(
    (r) => !favorites.includes(r.name) && !firstInTop.includes(r.name)
  );

  return (
    <Container className={classes.paper} component="main">
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          orientation={isMobile ? "vertical" : "horizontal"}
          variant="scrollable"
        >
          <Tab label={Days.pondelok} />
          <Tab label={Days.utorok} />
          <Tab label={Days.streda} />
          <Tab label={Days.stvrtok} />
          <Tab label={Days.piatok} />
        </Tabs>
      </Paper>

      {days.map((day, i) => (
        <TabPanel value={value} index={i} key={i}>
          <h2>Obľúbené reštaurácie</h2>
          <Grid container spacing={3}>
            {favoriteRestaurants.map((restaurant, key) => (
              <Grid item xs={12} sm={6} key={key} className={classes.tab}>
                <RestaurantDay restaurant={restaurant} day={day} />
              </Grid>
            ))}
            <Grid item xs={12} sm={6} className={classes.tab}>
              <RestaurantImageDay
                name="Dobre &amp; Chutne"
                data={<img alt="Dobre &amp; Chutne" src={dch} />}
                loading={dchLoading}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.tab}>
              <RestaurantImageDay
                name="Hotel Altis"
                data={<img alt="Hotel Altis" src={altis} />}
                loading={altisLoading}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.tab}>
              <RestaurantImageDay
                name="Šiki Pub"
                data={<img alt="Šiki Pub" src={siki} />}
                loading={sikiLoading}
              />
            </Grid>
            {/* {favoritesOtherRestaurants.map((restaurant, key) => (
              <Grid item xs={12} sm={6} key={key} className={classes.tab}>
                <RestaurantDay restaurant={restaurant} day={day} />
              </Grid>
            ))} */}
          </Grid>
          <h2>Ostatné reštaurácie</h2>
          <Grid container spacing={3}>
            {nonFavoriteRestaurants.map((restaurant, key) => (
              <Grid item xs={12} sm={6} key={key} className={classes.tab}>
                <RestaurantDay restaurant={restaurant} day={day} />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      ))}
    </Container>
  );
}
