declare global {
  interface Window {
    env: any;
  }
}

window.env = window.env || {};

let apiUrl = window.env.REACT_APP_API_URL;

export default {
  api: {
    getFood: apiUrl + "/index.php",
    sikiFood: apiUrl + "/siki.php",
    dobrechutneFood: apiUrl + "/dobrechutne.php",
    altis: apiUrl + "/altis.php",
  },
};
