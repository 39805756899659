import {
  LOAD_FOOD_FAILED,
  LOAD_FOOD_STARTED,
  LOAD_FOOD,
  LOAD_SIKI_FOOD_STARTED,
  LOAD_SIKI_FOOD,
  LOAD_SIKI_FOOD_FAILED,
  LOAD_DOBRECHUTNE_FOOD_STARTED,
  LOAD_DOBRECHUTNE_FOOD,
  LOAD_DOBRECHUTNE_FOOD_FAILED,
  LOAD_ALTIS_FOOD,
  LOAD_ALTIS_FOOD_FAILED,
} from "../../constants/action-types";
import config from "../../config";
import {
  setLoadingFood,
  setLoadingSiki,
  setLoadingDobreChutne,
} from "../loading/setLoading";
import { handleErrors } from "../../utils/handleErrors";
import { IRestaurant } from "../../constants/types";

export function loadFood() {
  return (dispatch: any) => {
    dispatch(setLoadingFood(true));
    dispatch(loadFoodStarted());

    return fetch(config.api.getFood, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(loadFoodSuccess(json));

        dispatch(setLoadingFood(false));
      })
      .catch((error: any) => {
        dispatch(loadFoodFailed(error));
        dispatch(setLoadingFood(false));
      });
  };
}

export const loadFoodStarted = () => ({
  type: LOAD_FOOD_STARTED,
});

export const loadFoodSuccess = (data: IRestaurant[]) => ({
  type: LOAD_FOOD,
  payload: data,
});

export const loadFoodFailed = (error: any) => ({
  type: LOAD_FOOD_FAILED,
  payload: { error },
});

export function loadSikiFood() {
  return (dispatch: any) => {
    dispatch(setLoadingSiki(true));
    dispatch(loadSikiFoodStarted());

    return fetch(config.api.sikiFood, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(loadSikiFoodSuccess(json.menu));

        dispatch(setLoadingSiki(false));
      })
      .catch((error: any) => {
        dispatch(loadSikiFoodFailed(error));
        dispatch(setLoadingSiki(false));
      });
  };
}

export const loadSikiFoodStarted = () => ({
  type: LOAD_SIKI_FOOD_STARTED,
});

export const loadSikiFoodSuccess = (data: IRestaurant[]) => ({
  type: LOAD_SIKI_FOOD,
  payload: data,
});

export const loadSikiFoodFailed = (error: any) => ({
  type: LOAD_SIKI_FOOD_FAILED,
  payload: { error },
});

export function loadDobreChutneFood() {
  return (dispatch: any) => {
    dispatch(setLoadingDobreChutne(true));
    dispatch(loadDobreChutneFoodStarted());

    return fetch(config.api.dobrechutneFood, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(loadDobreChutneFoodSuccess(json.menu));

        dispatch(setLoadingDobreChutne(false));
      })
      .catch((error: any) => {
        dispatch(loadDobreChutneFoodFailed(error));
        dispatch(setLoadingDobreChutne(false));
      });
  };
}

export const loadDobreChutneFoodStarted = () => ({
  type: LOAD_DOBRECHUTNE_FOOD_STARTED,
});

export const loadDobreChutneFoodSuccess = (data: IRestaurant[]) => ({
  type: LOAD_DOBRECHUTNE_FOOD,
  payload: data,
});

export const loadDobreChutneFoodFailed = (error: any) => ({
  type: LOAD_DOBRECHUTNE_FOOD_FAILED,
  payload: { error },
});

export function loadAltisFood() {
  return (dispatch: any) => {
    dispatch(setLoadingDobreChutne(true));
    dispatch(loadAltisFoodStarted());

    return fetch(config.api.altis, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(loadAltisFoodSuccess(json.menu));

        dispatch(setLoadingDobreChutne(false));
      })
      .catch((error: any) => {
        dispatch(loadAltisFoodFailed(error));
        dispatch(setLoadingDobreChutne(false));
      });
  };
}

export const loadAltisFoodStarted = () => ({
  type: LOAD_DOBRECHUTNE_FOOD_STARTED,
});

export const loadAltisFoodSuccess = (data: IRestaurant[]) => ({
  type: LOAD_ALTIS_FOOD,
  payload: data,
});

export const loadAltisFoodFailed = (error: any) => ({
  type: LOAD_ALTIS_FOOD_FAILED,
  payload: { error },
});
